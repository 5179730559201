import { getClientById } from './getClientById';
import { createClient } from './createClient';
import { updateClient } from './updateClient';
import { deleteClient } from './deleteClient';
import { getClients } from './getClients';
import { sunStudio } from '..';

export const api = sunStudio;

export { getClientById, createClient, updateClient, deleteClient, getClients };
