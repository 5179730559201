import { api } from '.';
import { CLIENT_RESOURCE } from '@/services/keys';

/**
 * @param {Client} client
 * @returns {Promise.<object>}
 */
export async function updateClient(client) {
  const partialUrl = api.createUrl({
    [CLIENT_RESOURCE]: client.id,
  });
  const { data } = await api.update(partialUrl, client.payload());
  return data;
}
