var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "card-form",
    [
      _c("template", { slot: "title" }, [_vm._v(" Basic Info ")]),
      _c(
        "template",
        { slot: "form" },
        [
          _c("form-row", {
            scopedSlots: _vm._u([
              {
                key: "left",
                fn: function () {
                  return [
                    _c(
                      "sun-label-group",
                      { attrs: { text: "Name" } },
                      [
                        _c("sun-input", {
                          attrs: {
                            id: `${_vm.mode}-${_vm.entity}-name`,
                            type: "text",
                            name: "name",
                            value:
                              _vm.item && _vm.item.name ? _vm.item.name : "",
                            "auto-validate": false,
                            "validate-on-blur": true,
                            minlength: 3,
                            maxlength: 50,
                            "text-error":
                              _vm.formErrors.name ||
                              _vm.i18nErrors.greaterThanThreeLessFiftyChars,
                            error: !!_vm.formErrors.name,
                            placeholder: "John Doe",
                            required: "required",
                          },
                          on: {
                            change: function ($event) {
                              _vm.formErrors.name = null
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "right",
                fn: function () {
                  return [
                    _c(
                      "sun-label-group",
                      { attrs: { text: "Email" } },
                      [
                        _c("sun-input", {
                          attrs: {
                            id: `${_vm.mode}-${_vm.entity}-email`,
                            type: "email",
                            value:
                              _vm.item && _vm.item.email ? _vm.item.email : "",
                            name: "email",
                            "text-error":
                              _vm.formErrors.email || _vm.i18nErrors.validEmail,
                            error: !!_vm.formErrors.email,
                            pattern:
                              "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+",
                            maxlength: 50,
                            "auto-validate": false,
                            "validate-on-blur": "",
                            placeholder: "email@agency.com",
                            required: "required",
                            readonly: !_vm.emailEditable,
                            disabled: !_vm.emailEditable,
                          },
                          on: {
                            change: function ($event) {
                              _vm.formErrors.email = null
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._t("default"),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }