import { api } from '.';
import { CLIENT_RESOURCE } from '@/services/keys';
/**
 *
 * @param {Client} client
 * @returns {Promise.<string>}
 */
export async function createClient(client) {
  const partialUrl = api.createUrl({
    [CLIENT_RESOURCE]: undefined,
  });
  const {
    data: {
      client: { id },
    },
  } = await api.create(partialUrl, client.payload());
  return id;
}
