import { CONTEXTS } from '../../model/shared/contexts';
import { ClientShared } from '../shared/ClientShared';
import { Address } from '../shared/Address';

export class SunStudioClient extends ClientShared {
  constructor(
    id = '',
    name = '',
    email = '',
    contactName = '',
    isExternal = false,
    users = [],
    taxId = '',
    address = new Address()
  ) {
    super(id, name, email, CONTEXTS.SUNSTUDIO.id, contactName, isExternal, users, taxId);
    this.address = address;
  }

  payload() {
    return {
      ...this,
    };
  }

  /**
   *
   * @param {{id:string, attributes: SunStudioClient}} entity
   * @param {Object} included
   * @return {SunStudioClient}
   */
  static create(entity) {
    return new SunStudioClient(
      entity.id,
      entity.attributes.name,
      entity.attributes.email,
      entity.attributes.contactName,
      entity.attributes.isExternal,
      null,
      entity.attributes.taxId,
      entity.attributes.address
    );
  }
}
