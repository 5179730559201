export const INFO = {
  en: {
    clientAssociate: 'User type client associated with this',
    advertiserAssociate: 'User type advertiser associated with this',
    adOpsAssociate: 'User type ad operations associated with this',
    financeAssociate: 'User type finance associated with this',
    sellerAssociate: 'User type seller associated with this',
    validCif: 'The valid tax identification number',
    publisherAssociate: 'User type publisher associated with this',
  },
};
