<template>
  <card-form>
    <template slot="title"> Basic Info </template>
    <template slot="form">
      <form-row>
        <template #left>
          <sun-label-group text="Name">
            <sun-input
              :id="`${mode}-${entity}-name`"
              type="text"
              name="name"
              :value="item && item.name ? item.name : ''"
              :auto-validate="false"
              :validate-on-blur="true"
              :minlength="3"
              :maxlength="50"
              :text-error="formErrors.name || i18nErrors.greaterThanThreeLessFiftyChars"
              :error="!!formErrors.name"
              placeholder="John Doe"
              required="required"
              @change="formErrors.name = null"
            />
          </sun-label-group>
        </template>
        <template #right>
          <sun-label-group text="Email">
            <sun-input
              :id="`${mode}-${entity}-email`"
              type="email"
              :value="item && item.email ? item.email : ''"
              name="email"
              :text-error="formErrors.email || i18nErrors.validEmail"
              :error="!!formErrors.email"
              pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+"
              :maxlength="50"
              :auto-validate="false"
              validate-on-blur
              placeholder="email@agency.com"
              required="required"
              :readonly="!emailEditable"
              :disabled="!emailEditable"
              @change="formErrors.email = null"
            />
          </sun-label-group>
        </template>
      </form-row>
      <slot />
    </template>
  </card-form>
</template>

<script>
import { ERRORS } from '@/i18n/forms/errors';
import { INFO } from '@/i18n/forms/info';
import CardForm from '@/components/atoms/CardForm';
import FormRow from '@/components/atoms/FormRow/FormRow';

export default {
  name: 'BasicInfo',
  components: {
    FormRow,
    CardForm,
  },
  props: {
    mode: {
      type: String,
      default: () => 'new',
    },
    entity: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      default: () => {},
    },
    emailEditable: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      i18nErrors: ERRORS.en,
      i18nInfo: INFO.en,
      formErrors: {
        name: null,
        email: null,
      },
      hasError: false,
    };
  },
};
</script>
