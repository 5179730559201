import { api } from '.';
import { CLIENT_RESOURCE, LINKED_USER_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import contextualEntityParser from '@/entities/contextual/ContextualEntityParser';

export const getResources = () => ({
  [CLIENT_RESOURCE]: undefined,
});

/**
 *
 * @param {QueryParamsBuilder} params
 * @returns {Promise.<{data:Client[], meta:import('@/model/shared/ResponseBuilder').EndpointData>}
 */
export async function getClients(params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl(getResources());
  params.addInclude(LINKED_USER_RESOURCE);

  const { data } = await api.get(partialUrl, params.buildWithoutPage());
  return new ResponseBuilder(contextualEntityParser.parse(data), data.meta).build();
}
