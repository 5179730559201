import { api } from '.';
import { CLIENT_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import SunStudioEntityParser from '@/entities/sunStudio/SunStudioEntityParser';

/**
 *
 * @param {string} clientId
 * @returns {Promise.<{data:SunStudioEntityParser[],meta:import('@/model/shared/ResponseBuilder').EndpointMeta}>}
 */
export async function getClientById(clientId, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl({
    [CLIENT_RESOURCE]: clientId,
  });

  const { data } = await api.get(partialUrl, params.buildWithoutPage());
  return new ResponseBuilder(SunStudioEntityParser.parse(data), data.meta).build();
}
