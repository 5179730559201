import { api } from '.';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { CLIENT_RESOURCE, LINKED_USER_RESOURCE, REGION_OFFICE_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import dashboardSSPEntityParser from '@/entities/dashboardSSP/DashboardSSPEntityParser';

export const getResources = clientId => ({
  [CLIENT_RESOURCE]: clientId,
});

/**
 *
 * @param {string} clientId
 * @returns {Promise.<{data:DashboardSSPClient, meta:import('@/model/shared/ResponseBuilder').EndpointData>}
 */
export async function getClientById(clientId) {
  const partialUrl = api.createUrl(getResources(clientId));
  const params = new QueryParamsBuilder();
  params.addInclude(LINKED_USER_RESOURCE, REGION_OFFICE_RESOURCE);

  const { data } = await api.get(partialUrl, params.buildWithoutPage());
  return new ResponseBuilder(dashboardSSPEntityParser.parse(data), data.meta).build();
}
